<template>
  <b-card
    no-body
  >
    <b-card-body>
      <b-card-title>{{$t('Payments')}}</b-card-title>
    </b-card-body>
    <b-table
      striped
      responsive
      :items="payments"
      class="mb-0"
      style="max-height: 40vh;"
      sticky-header="true"
      :no-border-collapse="true"
      :fields="tableColumns"
      show-empty
      :empty-text="$t('table.empty')"
    >
      <template #cell(details)="row">
        <b-form-checkbox
          v-model="row.detailsShowing"
          @change="row.toggleDetails"
        >
          {{ row.detailsShowing ? $t('table.hide') : $t('table.show') }}
        </b-form-checkbox>
      </template>

      <!-- full detail on click -->
      <template #row-details="row">
        <b-card>
          <b-row class="mb-2">
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>ID : </strong>{{ row.item.id }}
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>{{$t('Payments')}} ID : </strong>{{ row.item.payment_id }}
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>{{$t('fields.created_at')}} : </strong>{{ new Date(row.item.created_at).toLocaleDateString() }}
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>{{$t('fields.updated_at')}} : </strong>{{ new Date(row.item.updated_at).toLocaleDateString() }}
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>{{$t('fields.deleted_at')}} : </strong>{{ row.item.deleted_at ? new Date(row.item.deleted_at).toLocaleDateString() : '' }}
            </b-col>
          </b-row>

        </b-card>
      </template>

      <template #cell(status)="data">
            <b-badge v-if="data.item.valid_until" :variant="itemIsActive(data.item)?'light-success':'light-danger'">
              {{ itemIsActive(data.item) ? 'Active' : 'Inactive' }}
            </b-badge>
        </template>

      <template #cell(valid_from)="data">
          {{ data.item.valid_from ? new Date(data.item.valid_from).toLocaleDateString() :''}} 
        </template>
      <template #cell(valid_until)="data">
          {{ data.item.valid_until ?new Date(data.item.valid_until).toLocaleDateString() :''}}
        </template>
        <template v-if="show_edit_column" #cell(actions)="data">
          <b-button
            v-if="canBeEdited(data.item.payment_method_id)"
            variant="outline-secondary"
            type="button"
            @click="setPayment(data.item.id)"
          >
            {{$t('forms.edit')}}
          </b-button>
        </template>

    </b-table>
  </b-card>
</template>

<script>
import {
  BRow,
    BCol,
  BCard, BTable,BBadge,  BButton, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard, BTable,BBadge, BButton, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,
  },
  props: {
    show_edit_column: {
      type: Boolean,
      required: false,
    },
    payments: {
      type: Array,
      required: true,
    },
    blocked_payment_methods: {
      type: Array,
      required: false
    }
  },
  data(){
    let tableColumns = [
        { key: 'details', sortable: false },
        { key: 'payment_method_id', sortable: true },
        { key: 'status' },
        { key: 'valid_from', sortable: true },
        { key: 'valid_until', sortable: true },
    ]
    if(this.show_edit_column){
      tableColumns.push({key: 'actions'})
    }
    return {
      tableColumns
    }
  },
  methods: {
    itemIsActive(item){
      if(new Date(item.valid_until) > new Date() ){
        return true
      } else {
        return false
      }
    },
    canBeEdited(payment_method_id){
      if(this.blocked_payment_methods){
        return !this.blocked_payment_methods.includes(payment_method_id)
      }
      return false
    },
    setPayment(payment_id){
      this.$emit('set-payment', payment_id);
    }
  }
}
</script>

<style>

</style>
